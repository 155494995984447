.home-image {
  background-image: url('./components/images/camera.jpg');
  background-size: cover;
}

.about-header {
	background-image: linear-gradient(to right, green, white);
}

/* for mobile */
@media screen and (max-width: 767px) {
	.top-nav {
		display: none;
	}

	.carousel-image {
		height: '100%';
	}

	.top-logo {
		display: block;
  		margin-left: auto;
  		margin-right: auto;
	}

	.about-image {
		display: none;
	}

	.about-header-text {
		margin-top: '5rem';
	}
}
